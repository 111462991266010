var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-container", { staticClass: "d-flex justify-center mb-6" }, [
        _c(
          "div",
          { staticClass: "pt-12" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { "lazy-validation": "" },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "no-border" },
                  [
                    _c("v-card-title", { staticClass: "pb-0" }, [
                      _c("div", { staticClass: "text-center pb-16" }, [
                        _c("img", {
                          staticClass: "hidden-xs-only pt-9",
                          attrs: {
                            src: require("@/assets/logo-cor-primaria.png"),
                            width: "148",
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "no-border", staticStyle: { width: "340px" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "pb-0 pt-0 title-login-flow" },
                      [_vm._v(" Autenticação de dois fatores")]
                    ),
                    _c(
                      "div",
                      { staticClass: "pl-2" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-0 pb-0",
                            attrs: { cols: "12", sm: "12" },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "switch-tfa",
                              attrs: { inset: "", "hide-details": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "inline-grid",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "switch-tfa-subtitle",
                                            },
                                            [
                                              _vm._v(
                                                "Autenticação de dois fatores"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "subtitle-switch-login-flow",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.twoFactorAuthenticationEnabled ===
                                                      true
                                                      ? "Ativado"
                                                      : "Desativado"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.twoFactorAuthenticationEnabled,
                                callback: function ($$v) {
                                  _vm.twoFactorAuthenticationEnabled = $$v
                                },
                                expression: "twoFactorAuthenticationEnabled",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.twoFactorAuthenticationEnabled
                      ? _c("v-card-title", { staticClass: "body-login-flow" }, [
                          _vm._v(
                            " Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail cadastrado em todas as suas tentativas de acesso ao Agnes. "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.twoFactorAuthenticationEnabled
                      ? _c("v-card-title", { staticClass: "body-login-flow" }, [
                          _vm._v(
                            " Você desativou a verificação de segurança em duas etapas da sua conta. "
                          ),
                        ])
                      : _vm._e(),
                    _c("v-card-title", { staticClass: "body-login-flow" }, [
                      _vm._v(
                        " Se desejar " +
                          _vm._s(
                            _vm.twoFactorAuthenticationEnabled == true
                              ? "ativar"
                              : "desativar"
                          ) +
                          " futuramente, visite as configurações da sua conta. "
                      ),
                    ]),
                    _c(
                      "v-card-actions",
                      { staticClass: "button-action-login-flow" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.save()
                              },
                            },
                          },
                          [_vm._v(" Salvar configuração ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }