<template>
    <v-app>
        <v-container class="d-flex justify-center mb-6">
            <div class="pt-12">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card class="no-border">
                        <v-card-title class="pb-0">
                            <div class="text-center pb-16">
                                <img :src="require('@/assets/logo-cor-primaria.png')" width="148"
                                    class="hidden-xs-only pt-9" />
                            </div>
                        </v-card-title>
                    </v-card>
                    <v-card style="width: 340px;" class="no-border">
                        <v-card-title class="pb-0 pt-0 title-login-flow">
                            Autenticação de dois fatores</v-card-title>
                        <div class="pl-2">
                            <v-col cols="12" sm="12" class="pt-0 pb-0">
                                <v-switch v-model="twoFactorAuthenticationEnabled" inset hide-details class="switch-tfa">
                                    <template v-slot:label>
                                        <div style="display: inline-grid">
                                            <span class="switch-tfa-subtitle">Autenticação de dois fatores</span>
                                            <span class="subtitle-switch-login-flow">
                                                {{ twoFactorAuthenticationEnabled === true ? 'Ativado' : 'Desativado' }}
                                            </span>
                                        </div>

                                    </template>
                                </v-switch>
                            </v-col>
                        </div>
                        <v-card-title v-if="twoFactorAuthenticationEnabled" class="body-login-flow">
                            Para proteger ainda mais a sua conta, enviaremos um código de verificação para o seu e-mail
                            cadastrado em todas as suas tentativas de acesso ao Agnes.
                        </v-card-title>
                        <v-card-title v-if="!twoFactorAuthenticationEnabled" class="body-login-flow">
                            Você desativou a verificação de segurança em duas etapas da sua conta.
                        </v-card-title>
                        <v-card-title class="body-login-flow">
                            Se desejar {{twoFactorAuthenticationEnabled == true ? 'ativar' : 'desativar'}} futuramente, visite as configurações da sua conta.
                        </v-card-title>
                        <v-card-actions class="button-action-login-flow">
                            <v-spacer />
                            <v-btn color="primary" @click="save()">
                                Salvar configuração
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </div>
        </v-container>
    </v-app>
</template>
<script>
import userService from '@/services/userService.js';

export default {
    name: 'TfaFirstAccess',
    props: {
        id: {
            require: true
        }
    },

    data: () => ({
        valid: false,
        twoFactorAuthenticationEnabled: true,
        loading: false
    }),

    methods: {
        save() {
            this.twoFactorAuthenticationEnabled == false ? userService.disableTwofa(this.id) : userService.enableTwofa(this.id);
            this.$router.push('/login');
        }
    },
}

</script>
<style scoped>
.switch-tfa-subtitle {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    height: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    letter-spacing: 0.01em;
    color: #182026 !important;
}

.no-border{
    box-shadow: none !important;
    border: 0px solid #e7e7fa !important;
}

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: white !important;
}

.switch-tfa >>> .v-input--switch__track{
    opacity: 100 !important;
}

</style>

